import axios from '@axios'

export default {
  namespaced: true,
  state: {
    roles: [],
  },
  getters: {
    GetCurrentRoles: state => state.roles,
  },
  mutations: {
    SetRoles: (state, roles) => {
      state.roles = roles
    },
  },
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/accounts/accounts', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/accounts/accounts/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/accounts/accounts', userData)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/accounts/accounts/${userData.id}`, userData.userData)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteUser(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/accounts/accounts/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    forceDeleteUser(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/accounts/accounts/${id}`, { params: { definitive_delete: true } })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchRoles(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/accounts/roles', { params: queryParams })
          .then(response => {
            ctx.commit('SetRoles', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
